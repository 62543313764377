import firebase from 'firebase'

const firebaseConfig = {
	apiKey: "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCoKAC7b6uLaQfn\n56BZjxi7+0ULYJ+y/X/i+iF+APXiYHK+o4lbuqH/+BrOb5K7Ej/m3+DA+Etak87P\n8EBwldViOqKYeXlbulvnsqu3Yu92E26PzFOFC/4KqG6dxzMyWmBYkB/nUeAS3U+y\nVIZ5F/l6K3okm4C1GnMx3UKfFWCSvQTwEAZBmMTcp8e/CKfCYtXlL4zDSe9mZvlv\n3Qj/T1mjWO/ethJgZT71anMOY9vFa7UOZlUbhnvfjbrDLmlmII0ApVaciszdn1sj\nXsqBk4eCoblk520brcc2Ru6f5KVQsA7f0JZCqlMBtROKuEkrK+UNWPmXdH3a4EM/\nl1JHiAVXAgMBAAECggEABk7urOY+kdqDdAOJ53I2PYi3b8ehlhrRrmRipBIWWo/y\nhHMo8J3RMAO3XCE7AihoHBnJmiB2eAc6TfpRLVtIB9XzsM0PbKdShVNIz90Ds/Lj\nErzAMV1gaJ2MlzMYf5ev+TXG8wWf5o7fS4bFckuGjdSNuEvavNb2LvVPAR4Eyak2\nXzIioA5NlHrIigVedROvgvxnMhlJ8HC5XvXKKnfnv5/S3dCFkz3mUpSZWx89FgX8\niImCTKW2XW77Zu+KkiIHXmzkGVnOAUK1HaL30H/hsOo/TruneST6WJB4+e6rHnsT\nzZ/gVkfdKFS92RC9GR8XB3/bFy8I9YMVc//gEGg3cQKBgQDrSwDzog9bqgQaA2HO\n6sF9iYdVE+XprrubiXkvHPFb06pT1s1hOq07oJO/l0qeGCejNs4ZtorC3M9axDll\nthimmqk5ozjL368qiqAZiYLJc43zZZLsTRbSwum/cBbQ5etSr+k7LrKs5M1+DErd\nK3+ZNCBkUhrmtMnqVjYqI2nZHwKBgQC29HPyOHtkXkVxtx8aOkNCdrJXHruA7Ll8\nIP7Lt6CLwsYHK7qbjkXK2JW77aez9KAJaoJ3WpHTtr0J2h+xu+wBQb5Kw2+EboF3\nbYZK0OeX7c43TrfrniqiqBcyufjpOfSU9ps0CS4UwYPLHf0oqmrT+1LCz40t98oH\nH61pKyX0yQKBgQDjrKzcEqmXwfi8Dx+6b7AQwF4ideGX8Ar7ItgPPYtSlosPGx+a\ngIOV2fh9gHCkb60TInnlqZu1mKJQKuV7ROuvcqJi8kyIAgbmPENU0pmqQtVNerDj\nVGjMQeya4GRDeObvGOau7K3Z3H/3AwAB6RBL94OMb2I2cSOcTBWXSrMmcwKBgCEg\n1Jnoplc/Rr3Xldcu85zmV1a1JuiqdM/IPbrvri+AflEwFuMx+OtJGpvj5bkNM6lg\n0uz/xmYasdJF3/VT/OEhYW6yQtAtHl5cw7NqbnUxR2T2aRFOG5RGcaTqzeqVZvdO\nTD4451I01EOEU6H4cc2etZa29G59buSpVMI1YPMZAoGBAOhx1S4BJ6dH0XyEGipN\nDp6bDIclRN767g95S3Ps0e12PHwn68nb1alQuiJO1T9Qrcsk9wUc0qa9IDcGPCqy\n18HTqVy4RnSrJQpStlVluA2pozarrHCAtf5WzYkOLxMhDtDTecXD+H4CdWwcEYp2\nLcZSt/I/73INwcNhmBJO4iVN\n-----END PRIVATE KEY-----\n",
	authDomain: "rankplus-c9195.firebaseapp.com",
	projectId: "rankplus-c9195",
	storageBucket: "rankplus-c9195.appspot.com",
	messagingSenderId: "959635100670",
	appId: "1:959635100670:web:33d173aa9716bc101ca6af",
	measurementId: "G-GLPRE6GDBK",
	databaseURL: "https://rankplus-c9195-default-rtdb.firebaseio.com/",
};

if (typeof window !== undefined) {
	try {

		firebase.initializeApp(firebaseConfig)


		// const remoteConfig = firebase.remoteConfig();
		// remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

		// remoteConfig.defaultConfig = {
		// 	"welcome_message": "Welcome To Coco"
		// };
		// const val = remoteConfig.getValue("welcome_message");

		// const analytics = firebase.analytics();
		// analytics.logEvent("app initialized")

		// const messaging = firebase.messaging();
		// messaging.getToken({ vapidKey: "BDNDh9vYV5vzrbWMFqiwTRbgYfUrXj6apWnFFRBN-fJGFHBh8Fg18ZhDxwZKvIv5qf4JV-yfpdH6mYwkz1WOzng" })
		// 	.then((currentToken) => {
		// 		if (currentToken) {
		// 			console.log("token fcm", currentToken)
		// 			window.fcmToken = currentToken
		// 			LoadingRef.current && LoadingRef.current.updateDevice()
		// 		} else {
		// 			console.log('No registration token available. Request permission to generate one.');
		// 		}
		// 	}).catch((err) => {
		// 		console.log('An error occurred while retrieving token. ', err);
		// 	});

		// messaging.onMessage((payload) => {
		// 	console.log('Message received. ', payload);
		// });
	} catch (e) {
		console.warn("firebase error catch ", e)
	}

}




export const firebaseClient = firebase;
