import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Flex, FormLabel, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { Box } from "@chakra-ui/layout";
import moment from 'moment';

const PollCreateForm = ({ editPoll, pollModalOpen, setPollModalOpen, handlePollSubmit, pollDetails, getPollChatDetails, pollRoomId, handleAddtoAllRooms, addToAllRooms }) => {
    const pollDefaultObj = {
        pollTime: "",
        pollQuestion: "",
        showQuestion: false,
        pollStatus: "Ready",
        pollAnswer: 0,
        pollOptions: [{ key: 1, value: "" }],
    }
    const [polls, setPolls] = useState([pollDefaultObj]);
    useEffect(() => {
        pollDetails && setPolls(pollDetails);
    }, [pollDetails])

    useEffect(() => {
        if (pollRoomId) {
            getPollChatDetails(pollRoomId)
        }
    }, [pollRoomId])

    const handleSelect = (event, index) => {
        const { id, value } = event.target;
        setPolls(polls?.map((poll, i) => {
            if (i === index) {
                return {
                    ...poll,
                    [id]: value==="true"?true:value==="false"?false:value
                }
            }
            return poll;
        }));
    }

    const handleQuesTextChange = (text, index) => {
        setPolls(polls?.map((poll, i) => {
            if (i === index) {
                return {
                    ...poll,
                    pollQuestion: text
                }
            }
            return poll;
        }));
    };

    const handleOptTextChange = (text, pollIndex, optIndex) => {
        setPolls(polls?.map((poll, i) => {
            if (i === pollIndex) {
                return {
                    ...poll,
                    pollOptions: poll?.pollOptions?.map((option, j) => {
                        if (j === optIndex) {
                            return { ...option, value: text };
                        }
                        return option;
                    })
                }
            }
            return poll;
        }));
    };

    const handleAddOption = (index) => {
        setPolls(polls?.map((poll, i) => {
            if (i === index) {
                const optionIndex = poll.pollOptions.length;
                return {
                    ...poll,
                    pollOptions: [...poll.pollOptions, { key: optionIndex + 1, value: "" }]
                }
            }
            return poll;
        }));
    };

    const handleDeleteOption = (pollIndex, OptIndex) => {
        setPolls(polls?.map((poll, i) => {
            if (i === pollIndex) {
                return {
                    ...poll,
                    pollOptions: poll.pollOptions.filter((_, j) => j !== OptIndex)
                }
            }
            return poll;
        }));
    };

    const handleAddPoll = () => {
        setPolls([...polls, { ...pollDefaultObj }]);
    };

    const handleDeletePoll = (index) => {
        setPolls(polls.filter((_, i) => i !== index));
    };

    const findDuplicates = (arr) => {
        const seen = new Set();
        const duplicates = [];
      
        arr.forEach(item => {
          if (seen.has(item.value)) {
            duplicates.push(item.value);
          } else {
            seen.add(item.value);
          }
        });

        if(duplicates?.length===0){
            return false;
        }
        else {
            return true
        }
      };

    const _validatePolls = () =>{

        for(let i = 0; i < polls?.length ; i++){
            if(polls[i]?.pollTime?.length>0 || polls[i]?.pollTime>0){
                if (parseInt(polls[i].pollTime) < 1 || polls[i].pollTime > 30) {
                    alert(`Poll ${i + 1} duration must be between 1 and 30 minutes.`);
                    return false;
                }
            }
            else{
                alert(`Poll ${i + 1} duration is required.`);
                return false;
            }
            
            // question
            if (!polls[i].pollQuestion.trim()) {
                alert(`Poll ${i + 1} question is required.`);
                 return false;
            }
            
            // Validate options
            const emptyOption = polls[i].pollOptions.some(option => !option.value.trim());
            if (emptyOption) {
                alert(`Poll ${i + 1} has empty options. Please fill in all options.`);
                return false;
            }else if(findDuplicates(polls[i].pollOptions)){
                alert(`Poll ${i + 1} has duplicate options. Please provide unique options`);
                return false;
            }
            else if(polls[i].pollOptions?.length<2){
                alert(`Poll ${i + 1} has only 1 option. Please provide more options`);
                return false;
            }
    
            // Validate answer 
            if (!(polls[i]?.pollAnswer?.length>0)) {
                alert(`Poll ${i + 1} answer must be selected.`);
                return false;
            }

        }
            
        return true;
    
    }

    const handleSubmit = () => {
        let validatePoll = _validatePolls();
        if(!validatePoll){
            return;
        }
        const pollData = polls?.map((poll, i) => {
            return {
                ...poll,
                pollCreatedDate: moment().format(),
                _id:moment().unix()+Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000,
            }
        });
        if (addToAllRooms) {
            if(editPoll){
                handleAddtoAllRooms(polls)
            }
            else{
                handleAddtoAllRooms(pollData)
            }
        }
        else {
            if(editPoll){
                handlePollSubmit(polls)
            }
            else{
                handlePollSubmit(pollData)
            }
        }

    };

    return (
        <Modal isOpen={pollModalOpen} size="xl" onClose={() => setPollModalOpen(false)} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{editPoll ? "Update Poll" : "Create Poll"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        {polls?.length > 0 && polls?.map((poll, i) => (
                            <Box key={i}>
                                <Text fontWeight={600}>Poll {i + 1}</Text>
                                <Box border="1px solid #8484843d" padding="5px" borderRadius="5px" marginBottom="5px">
                                    <Flex justifyContent="space-between">
                                        <Box w="48%">
                                            
                                            <FormLabel margin="0px">Select Poll Duration :</FormLabel>
                                            <input 
                                                min={1} 
                                                max={30} 
                                                type="number" 
                                                id="pollTime" 
                                                placeholder="Select minute" 
                                                style={{ 
                                                    display: "block",
                                                    width: "100%",
                                                    border: "1px solid #e2e8f0",
                                                    padding: 7,
                                                    borderRadius: 5,
                                                }} 
                                                value={poll.pollTime}
                                                onChange={(e) => handleSelect(e, i)}
                                                onInput={(e) => {
                                                    const value = e.target.value.replace(/[^\d]/g, '');
                                                    e.target.value = value;
                                                    handleSelect(e, i); 
                                                }}
                                            />
                                        </Box>
                                        <Box w="48%">
                                            <FormLabel margin="0px">Show Question :</FormLabel>
                                            <Select id="showQuestion" onChange={(e) => handleSelect(e, i)} value={poll.showQuestion}
                                                maxHeight="200px">
                                                <option key={1} value={true}>
                                                    true
                                                </option>
                                                <option key={2} value={false}>
                                                    false
                                                </option>
                                            </Select>
                                        </Box>
                                    </Flex>
                                    <FormLabel margin="0px">Question :</FormLabel>
                                    <Input
                                        style={{ marginBottom: '10px' }}
                                        placeholder="Enter your question..."
                                        value={poll.pollQuestion}
                                        onChange={(e) => handleQuesTextChange(e.target.value, i, "question")}
                                    />
                                    <FormLabel margin="0px">Option :</FormLabel>

                                    {poll?.pollOptions?.map((option, index) => (
                                        <Box key={index} width="100%" mb="5px">
                                            <span>{index + 1 + " ."}</span>
                                            <Input
                                                style={{ width: '80%', margin: '0px 10px' }}
                                                placeholder={`Enter option ${index + 1}...`}
                                                value={option.value}
                                                onChange={(e) => handleOptTextChange(e.target.value, i, index)}
                                            />
                                            {
                                                poll?.pollOptions?.length>1 &&
                                                    <IconButton
                                                        style={{ padding: "5px", backgroundColor: "#edf2f7" }}
                                                        variant="ghost"
                                                        // size="sm"
                                                        onClick={() => handleDeleteOption(i, index)}
                                                        icon={<DeleteIcon />}
                                                    />
                                            }
                                        </Box>
                                    ))}
                                    <Flex justifyContent="space-between" w="100%">
                                        <Button fontSize="14px" padding="10px" onClick={() => handleAddOption(i)}>Add Option</Button>
                                        {
                                            polls?.length>1?
                                                <IconButton
                                                    style={{ padding: "5px", color: "white", backgroundColor: "#e60000" }}
                                                    variant="ghost"
                                                    onClick={() => handleDeletePoll(i)}
                                                    icon={<DeleteIcon />}
                                                />
                                            :null
                                        }
                                    </Flex>
                                    <Box marginTop="5px">
                                        <FormLabel margin="0px">Select Answer :</FormLabel>
                                        <Select id="pollAnswer" placeholder="Select Answer" onChange={(e) => handleSelect(e, i)} value={poll.pollAnswer}
                                            maxHeight="200px">
                                            {poll?.pollOptions?.map((option, index) => (
                                                option.value.length > 0 &&
                                                <option option key={index} value={index + 1} >
                                                    {option.value}
                                                </option>
                                            ))}
                                        </Select>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                        {
                            editPoll !==true &&
                                <Button bg="#797DFA" color="white" onClick={handleAddPoll}>Add Poll</Button>
                        }
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setPollModalOpen(false)} me={2}>Cancel</Button>
                    <Button bg="#797DFA" color="white" onClick={handleSubmit}>
                        {
                            editPoll ? "Update Poll":"Create Poll"
                        }
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};

export default PollCreateForm;