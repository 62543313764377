import { ArrowDownIcon, ArrowUpIcon, ChevronRightIcon, CloseIcon, Search2Icon, SearchIcon, ChevronDownIcon, PhoneIcon, CopyIcon, CheckCircleIcon, DeleteIcon } from "@chakra-ui/icons";
import { Box, Container, Heading, Link } from "@chakra-ui/layout";
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    IconButton,
    Tag,
    Text,
    useDisclosure,
    List,
    ListItem,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Input,
    InputLeftElement,
    InputGroup,
    VStack,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    Divider,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Avatar,
    Icon,
    Image,
} from "@chakra-ui/react";
import Pagination from "@choc-ui/paginator";
import _, { map, size } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineAudio, AiOutlineClear, AiOutlineFileProtect, AiOutlineVideoCamera } from "react-icons/ai";
import { GrPowerReset } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ErrorChecker } from "../../Components/ErrorChecker";
import { STATUS } from "../../Constants";
import {
    closeDoubtAction,
    getAllTeachers,
    getDoubtsAction,
    getTeacherAssingedAction,
    getTeacherDoubtsAction,
    publicDoubtAction,
    readCommentAction,
    resetReadAction,
    resetTeacherAssignedData,
    updateDoubtAction,
} from "../../redux/reducers/doubt";
import { DiscussionComments } from "../../routes/DiscussionCommunity/DiscussionComments";
import { bilingualText, mobileView, webView } from "../../utils/Helper";
import { TeacherCourses } from "../TeacherCourses";
import { TeacherTests } from "../TeacherTests";
import { TeacherTests2 } from "../TeacherTests2";
import { TbDotsVertical } from "react-icons/tb";
import { UploadAnswerSheet } from "../../routes/Assignments/AssignmentDetails";
import { uploadFileAction } from "../../redux/reducers/website";
import { BaseURL } from "../../BaseUrl";

export const TeacherDoubtScreen = (props) => {
    const user = useSelector((s) => s.user.user);
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();

    const allTeachers = useSelector((state) => state.doubts.allTeachers);
    const teacherDoubts = useSelector((s) => s.doubts.teacherDoubts);

    const { pageConfig, closeDoubtStatus, teacherAssignedData } = useSelector((s) => ({
        pageConfig: s.doubts.pageConfig,
        closeDoubtStatus: s.doubts.closeDoubtStatus,
        teacherAssignedData: s.doubts.teacherAssignedData || [],
        user: s.user.user,
    }));

    const [searchDoubt, changeSearchDoubt] = useState();
    const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
    const [active, setActive] = useState();
    const [formKey, changeFormKey] = useState(0);
    const [currentTeacher, setCurrentTeacher] = useState();
    const [testDiscussion, openTestDiscussion] = useState();
    const [courseDiscussion, openCourseDiscussion] = useState();
    const [currentTab, setCurrentTab] = useState("0");

    useEffect(() => {
        if (allTeachers?.length && user.staff._id) {
            const staff = _.find(allTeachers, (t) => t._id === user.staff._id);
            setCurrentTeacher(staff);
        }
    }, [allTeachers, params]);



    const checkRole = useMemo(() => {
        return (
            user?.staff?.staffRole === "ADMIN" || user?.staff?.staffRole === "HEAD_TEACHER"

        );
    }, [user?.staff?.staffRole]);

    useEffect(() => {
        if (params?.staffId) dispatch(getTeacherAssingedAction({ assignedTo: params.staffId }));
        else if (user?.staff) dispatch(getTeacherAssingedAction({ assignedTo: user._id }));
        else dispatch(resetTeacherAssignedData());
    }, [teacherDoubts, dispatch, user, params]);



    const getDoubts = useCallback(() => {
        if (params.page)
            if (currentTeacher?.user._id) {
                dispatch(getTeacherDoubtsAction({ page: params.page, staff: currentTeacher?.user._id, doubt_text_regex: searchDoubt }));
            } else dispatch(getTeacherDoubtsAction({ page: params.page, staff: user?._id, doubt_text_regex: searchDoubt }));
    }, [params.page, params.staffId, dispatch, searchDoubt, user?._id]);

    const getTeachers = useCallback(() => {
        if (checkRole) dispatch(getAllTeachers());
    }, [dispatch, checkRole]);

    useEffect(() => {
        getDoubts();
    }, [getDoubts]);

    useEffect(() => {
        getTeachers();
    }, [getTeachers]);

    useEffect(() => {
        if (!params.page) {
            if (checkRole && allTeachers?.length) history.push("/doubts/1/" + allTeachers[0].user._id);
            else history.push("/doubts/1");
        }
    });

    useEffect(() => {
        if (closeDoubtStatus === STATUS.SUCCESS && active) {
            setActive(_.find(teacherDoubts, (d) => d._id === active._id));
        }
    }, [closeDoubtStatus, teacherDoubts, active]);

    const _openDoubt = (a) => {
        setActive(a);
        onOpen();

    };

    const handlePageChange = (page) => {
        if (checkRole) history.push(`/doubts/${page}/${params.staffId}`);
        else history.push("/doubts/" + page);
    };

    const handleChangeSearch = (e) => {
        changeSearchDoubt(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        handlePageChange(1);
    };

    const resetSearch = () => {
        changeSearchDoubt(null);
        changeFormKey((d) => d + 1);
        handlePageChange(1);
    };

    const handleTabChange = (e) => {
        setCurrentTab(e.target.value);
    };

    const handleOpenTestDiscussion = (test) => {
        openTestDiscussion((d) => (d ? null : test));
    };

    const handleOpenCourseDiscussion = (content) => {
        openCourseDiscussion((d) => (d ? null : content));
    };

    return (
        <Box>
            <Box p={3} bg="#EFF3F6">
                <div className="row align-items-center ">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        <Heading fontSize="lg">Teacher Doubts</Heading>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        {checkRole && (
                            <Box pr={5} flex={3}>
                                <MemorizeTeachersList resetSearch={resetSearch} />
                            </Box>
                        )}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        <Flex>
                            <Box fontSize={[14]}>Total Doubts: &nbsp;</Box>
                            <Box color={"brand.blue"} fontWeight={"bold"} fontSize={[16]}>
                                {teacherDoubts?.length}
                            </Box>
                        </Flex>
                        <Flex>
                            <Box fontSize={[14]}>Closed: &nbsp;</Box>
                            <Box color={"brand.blue"} fontWeight={"bold"} fontSize={[16]}>
                                {checkRole ? _.sumBy(allTeachers, "doubtCountResolved") : user.staff?.doubtCountResolved || 0}
                            </Box>
                        </Flex>
                        {checkRole ? (
                            <Flex>
                                <Box fontSize={[14]}>Teachers:</Box>
                                <Box color={"brand.blue"} fontWeight={"bold"} fontSize={[16]}>
                                    {allTeachers?.length || 0}
                                </Box>
                            </Flex>
                        ) : null}
                        {checkRole && (
                            <>
                                <br />
                                <MobileTeacherList resetSearch={resetSearch} />
                            </>
                        )}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        <Box w={["100%", "100%", "100%", "100%"]}>
                            <Select borderColor={["#C1C2C2"]} value={currentTab} onChange={handleTabChange} placeItems={"Select appointment status"}>
                                <option value={0}>Connect To Teacher</option>
                                <option value={1}>Video's Doubt</option>
                                <option value={2}>Audio's Doubt</option>
                                <option value={3}>Test's Doubt</option>
                                <option value={4}>Assignment's Doubt</option>
                            </Select>
                        </Box>
                    </div>
                </div>
            </Box>
            <Flex align={"stretch"}>
                {checkRole && (
                    <Box pr={5} {...webView} flex={3}>
                        <MemorizeTeachersList resetSearch={resetSearch} />
                    </Box>
                )}

                <Box flex={10}>
                    <Box>



                        <Box px={[1, 1, 1, 5]}>
                            {/* <Text fontSize="lg">{currentTeacher?.user?.name}</Text>
                            <Text color={"brand.secondary"}>{currentTeacher?.staffDesc}</Text> */}
                            <br />
                            <Box
                                // className="overflow-auto "
                                py={2}
                                px={1}
                                height={{ base: "70dvh", sm: "80dvh", md: "81dvh", lg: "82dvh", xl: "82dvh" }}
                                borderRadius={5}
                                boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                            >

                                {currentTab === "0" && (
                                    <HStack alignItems={"stre"} width={"100%"}>
                                        <Box flex={4}>
                                            <DoubtsList
                                                checkRole={checkRole}
                                                resetSearch={resetSearch}
                                                handleSearch={handleSearch}
                                                formKey={formKey}
                                                searchDoubt={searchDoubt}
                                                handleChangeSearch={handleChangeSearch}
                                                teacherDoubts={teacherDoubts}
                                                _openDoubt={_openDoubt}
                                                pageConfig={pageConfig}
                                                handlePageChange={handlePageChange}
                                                currentTeacher={currentTeacher}
                                            />
                                        </Box>
                                    </HStack>
                                )}


                                {currentTab === "1" && (
                                    <TeacherTests2 openDiscussion={handleOpenCourseDiscussion} itemModel={"Video"} currentTeacher={currentTeacher} handlePageChange={handlePageChange} />
                                )}

                                {currentTab === "2" && (
                                    <TeacherTests2 openDiscussion={handleOpenCourseDiscussion} itemModel={"Audio"} currentTeacher={currentTeacher} handlePageChange={handlePageChange} />
                                )}

                                {currentTab === "3" && (
                                    <TeacherTests2 openDiscussion={handleOpenTestDiscussion} itemModel={"Test"} currentTeacher={currentTeacher} handlePageChange={handlePageChange} />
                                )}

                                {currentTab === "4" && (
                                    <TeacherTests2 openDiscussion={handleOpenTestDiscussion} itemModel={"Assignment"} currentTeacher={currentTeacher} handlePageChange={handlePageChange} />
                                )}

                            </Box>
                        </Box>
                        {isOpen ? <DoubtDetails checkRole={checkRole} currentDoubt={active} isOpen={isOpen} onClose={onClose} /> : null}
                        {testDiscussion ? <DoubtDetails checkRole={checkRole} test={testDiscussion} isOpen={testDiscussion} onClose={handleOpenTestDiscussion} /> : null}
                        {courseDiscussion ? <DoubtDetails checkRole={checkRole} courseContent={courseDiscussion} isOpen={courseDiscussion} onClose={handleOpenCourseDiscussion} /> : null}
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

const DoubtsList = ({ currentTeacher, checkRole, resetSearch, handleSearch, formKey, searchDoubt, handleChangeSearch, teacherDoubts, _openDoubt, pageConfig, handlePageChange }) => {
    const [doubtInfo, setDoubtInfo] = useState([]);

    return (
        <>
            <>
                {/* <form key={formKey} onSubmit={handleSearch}>
                    <HStack width="50%" flexWrap={"wrap"} py={4} align="center">
                        <VStack flexShrink={0} width={130} spacing={0}>
                            <Text color={"brand.blue"} textAlign={"center"} fontWeight={"bold"} fontSize={[22]}>
                                {teacherDoubts?.length || 0}
                            </Text>
                            <Text textAlign={"center"} fontSize={[14]}>
                                Total Doubts
                            </Text>
                        </VStack>
                        <VStack flexShrink={0} width={130} spacing={0}>
                            <Text textAlign={"center"} color={"brand.blue"} fontWeight={"bold"} fontSize={[22]}>
                                {teacherDoubts?.length ? _.filter(teacherDoubts, (d) => d.status === "Closed")?.length : 0}
                            </Text>
                            <Text textAlign={"center"} fontSize={[14]}>
                                Solved Doubts
                            </Text>
                        </VStack>
                        <HStack flex={1} pt={[4, 4, 4, 0]}>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" children={<Search2Icon color="gray.300" />} />
                                <Input value={searchDoubt} name="text" onChange={handleChangeSearch} placeholder="Search Doubt" />
                            </InputGroup>
                            <Tooltip label="Search">
                                <IconButton type="submit" icon={<Search2Icon />} />
                            </Tooltip>
                            <Tooltip label="Reset">
                                <IconButton onClick={resetSearch} icon={<GrPowerReset fontSize={20} />} />
                            </Tooltip>
                        </HStack>
                    </HStack>
                    <br />
                </form> */}
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                        {teacherDoubts?.length ? (
                            map(teacherDoubts, (d, i) => {
                                return <DoubtItem doubtInfo={doubtInfo} setDoubtInfo={setDoubtInfo} onPress={_openDoubt} d={d} i={i + 1} />;
                            })
                        ) : (
                            <Text color={"brand.secondary"} textAlign="center">
                                No doubts available
                            </Text>
                        )}
                    </div>
                    <div style={{ width: "50%" }}>
                        <DoubtsInfo setDoubtInfo={setDoubtInfo} searchDoubt={searchDoubt} currentTeacher={currentTeacher} doubtInfo={doubtInfo} />
                    </div>
                </div>

            </>
            <br />
            <br />

            {/* {pageConfig ? (
                <HStack align="center">
                    <Pagination
                        current={pageConfig?.page}
                        total={pageConfig?.total}
                        pageSize={pageConfig?.limit}
                        onChange={handlePageChange}
                        paginationProps={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                        colorScheme="blue"
                    />
                </HStack>
            ) : null} */}
        </>
    );
};

const CustomTab = ({ title }) => {
    return (
        <Tab marginX={[1, 1, 1, 2]} p={2} background="#EFF3F6" color={"black"} _selected={{ background: "brand.blue", color: "white", borderRadius: 4 }}>
            {title}
        </Tab>
    );
};

const MobileTeacherList = ({ resetSearch }) => {
    const params = useParams();
    const { teachers } = useSelector((state) => ({
        teachers: state.doubts.allTeachers,
    }));

    const [currentTeacher, setTeacher] = useState();
    const [openIndex, changeOpenIndex] = useState([0]);

    useEffect(() => {
        changeOpenIndex([]);
    }, [params.staffId]);

    useEffect(() => {
        if (params.staffId && teachers) {
            let staff = _.find(teachers, (t) => t.user?._id === params.staffId);
            setTeacher(staff);
        }
    }, [params, teachers]);

    const handleOpen = () => {
        changeOpenIndex((d) => (d.length ? [] : [0]));
    };

    return (
        <>
            <Accordion index={openIndex} allowMultiple {...mobileView}>
                <AccordionItem>
                    <AccordionButton onClick={handleOpen}>
                        <Box flex="1" textAlign="left">
                            <HStack justify={"space-between"}>
                                <Text color={"brand.blue"}>{currentTeacher?.user.name}</Text>
                                <Text>Teachers ({teachers?.length}) &nbsp;</Text>
                            </HStack>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <MemorizeTeachersList resetSearch={resetSearch} hideHeading />
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Flex justifyContent="flex-end" display={["flex", "flex", "flex", "none"]} my={2}>

            </Flex>
        </>
    );
};

const TeachersList = ({ hideHeading, resetSearch }) => {
    const history = useHistory();
    const params = useParams();

    const [allTeachers, changeTeachers] = useState([]);
    const [sortOn, setSortOn] = useState("doubt");
    const [sortType, changeSortType] = useState("asc");
    const { teachers } = useSelector((state) => ({
        teachers: state.doubts.allTeachers,
    }));

    useEffect(() => {
        if (teachers?.length) changeTeachers(teachers);
    }, [teachers]);

    useEffect(() => {
        handleSort();
    }, [sortOn, sortType]);


    const handleTeacher = (teach) => {
        resetSearch();
        history.push(`/doubts/1/${teach.user?._id}`);
    };

    const handleChange = (e) => {
        let searchText = e.target.value;
        let list = _.filter(teachers, (t) => _.includes(_.lowerCase(t?.user?.name), _.lowerCase(searchText)));
        changeTeachers(list);
    };
    const handleSort = () => {
        if (sortOn === "doubt") changeTeachers((d) => _.orderBy(d, (doubt) => (!doubt.doubtCount ? 0 : doubt.doubtCount), sortType));
        else changeTeachers((d) => _.orderBy(d, (doubt) => (!doubt.doubtCountResolved || !doubt.doubtCount ? 0 : doubt.doubtCountResolved / doubt.doubtCount), sortType));
    };
    return (
        <List h="100%">
            {hideHeading ? null : (
                <ListItem background={"gray.100"} borderRadius={4} py={2} px={3}>
                    <HStack>
                        <Text>Teachers</Text>
                        <Text>({allTeachers?.length || 0})</Text>
                    </HStack>
                </ListItem>
            )}
            <ListItem my={3}>
                <HStack>
                    <Box flex={1}>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none" children={<Search2Icon color="gray.300" />} />
                            <Input onChange={handleChange} placeholder="Search Teacher" />
                        </InputGroup>
                    </Box>
                </HStack>
            </ListItem>
            <ListItem my={3}>
                <Flex>
                    <Button
                        onClick={() => changeSortType((d) => (d === "asc" ? "desc" : "asc"))}
                        size="sm"
                        variant="outline"
                        colorScheme="blue"
                        width={"100%"}
                        mr={3}
                        rightIcon={sortType === "asc" ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    >
                        <Box>Sort </Box>
                    </Button>
                    <Menu>
                        <MenuButton as={Button} size="sm" px={4} colorScheme="blue" rightIcon={<ChevronDownIcon />}>
                            {sortOn === "doubt" ? "Count" : "Top"}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => setSortOn("doubt")}>Doubt count</MenuItem>
                            <MenuItem onClick={() => setSortOn("top comment")}>Top</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </ListItem>
            <ListItem my={3}>
                <HStack>
                    <Box flex={1}></Box>
                </HStack>
            </ListItem>
            <List
                maxH="100vh"
                overflowY="scroll"
                overflowX="hidden"
                pos="sticky"
                top="0px"
                css={{ "&::-webkit-scrollbar": { width: "6px" }, "&::-webkit-scrollbar-thumb": { background: "#cdcdcd", borderRadius: "5px" } }}
            >
                {allTeachers?.length
                    ? allTeachers.map((teach) => {
                        const active = teach.user?._id === params.staffId;
                        return (
                            teach.user && (
                                <ListItem
                                    borderRadius={active ? 5 : 2}
                                    my={1}
                                    cursor={"pointer"}
                                    _hover={{ background: "blue.200", color: "white" }}
                                    py={1}
                                    px={2}
                                    key={teach._id}
                                    transition="all .3s"
                                    onClick={() => handleTeacher(teach)}
                                    background={active && "brand.blue"}
                                    color={active ? "white" : "brand.secondary"}
                                >
                                    <HStack justify={"space-between"} alignItems="flex-start">
                                        <Box pt={1}>
                                            <Text>{teach.user?.name}</Text>
                                            <Text fontSize="xs">{teach.staffDesc}</Text>
                                        </Box>
                                        <HStack display={"flex"}>
                                            <Box fontSize={11}>
                                                <Box>Doubts</Box>
                                                <Flex justifyContent="center">
                                                    <Box>{teach?.doubtCountResolved || 0}</Box>
                                                    <Box px={1}>/</Box>
                                                    <Box textAlign="left">{teach.doubtCount || 0}</Box>
                                                </Flex>
                                            </Box>
                                            {<ChevronRightIcon visibility={active ? "" : "hidden"} fontSize={24} color={"white"} />}
                                        </HStack>
                                    </HStack>
                                </ListItem>
                            )
                        );
                    })
                    : null}
            </List>
        </List>
    );
};

const MemorizeTeachersList = React.memo(TeachersList);


const DoubtItem = ({ onPress, d, i, setDoubtInfo, doubtInfo }) => {
    const dispatch = useDispatch();
    const [isCopied, setIsCopied] = useState(false);
    const doubtStatus = d.status === "Closed" ? { label: "Closed", color: "red" } : { label: d.status, color: "green" };
    const publicStatus = { label: "Public", color: "green" };
    const copyText = (text) => {
        if (!navigator || isCopied) return;
        navigator.clipboard.writeText(text);
        setIsCopied(true);
        window.setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    return (
        <Flex onClick={() => setDoubtInfo(d)} p={2} px={4} bg={d._id === doubtInfo?._id ? "gray.100" : "white"} // If clicked, set bg to gray.100, otherwise white
            boxShadow={d._id === doubtInfo?._id ? "sm" : "none"} _hover={{ bg: "gray.50", boxShadow: "sm" }} cursor="pointer" border="1px solid #dddddd" borderRadius={"md"} m={1}>
            <Box mr={5}>
                <Text fontSize="" color="gray.700">
                    {i}
                </Text>
            </Box>
            <Box>
                <Flex>
                    <Text fontSize="sm" color="gray.600">
                        {d.userId?.name}
                    </Text>
                    <Text color="gray.500" fontSize="xs" ml={10}>
                        <i>{moment(d.createdAt).format("DD MMM YYYY hh:mm a")}</i>
                    </Text>
                </Flex>
                <Box pb={2} pt={1}>
                    <Text fontSize={"sm"}>{d.doubt?.[d?.doubt.length - 1].doubt_query}</Text>
                    {/* {console.log("doubt",d.doubt?.[d.length-1])} */}
                </Box>
                <HStack mt={3}>
                    <Tag size="sm" colorScheme={doubtStatus.color}>
                        {doubtStatus.label}
                    </Tag>
                    {d.public ? <Tag colorScheme={publicStatus.color}>{publicStatus.label}</Tag> : null}
                    <Box display="flex" alignItems="center" justifyContent="space-between" w="100%">
                        <Popover>
                            <PopoverTrigger>
                                <Tag _hover={{ boxShadow: "lg" }} size="sm" colorScheme="blue">
                                    <PhoneIcon color="blue.400" />
                                </Tag>
                            </PopoverTrigger>

                            <PopoverContent boxShadow="none!important">
                                <PopoverArrow />
                                <Flex justifyContent="space-between">
                                    <PopoverBody textAlign="center" flexGrow="1">
                                        {d.userId?.contact}
                                    </PopoverBody>
                                    <Button variant="ghost" mr={2} onClick={() => copyText(d.userId?.contact)}>
                                        {isCopied ? <CheckCircleIcon color="green.400" /> : <CopyIcon />}
                                    </Button>
                                </Flex>
                            </PopoverContent>
                        </Popover>
                    </Box>
                </HStack>
            </Box>
        </Flex>
    );
};

const DoubtDetails = ({ isOpen, onClose, currentDoubt, test, courseContent, checkRole }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(resetReadAction());
    }, [dispatch]);

    useEffect(() => {
        let itemId = test?.itemId || courseContent?.itemId;
        if (itemId && !checkRole) dispatch(readCommentAction({ itemId }));
    }, [dispatch, courseContent, test, checkRole]);

    return (
        <Drawer isOpen={isOpen} size={["xl"]} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent pt={10}>
                <DrawerCloseButton mt={10} />
                <DrawerHeader>
                    {test ? (
                        <Box alignItems="center">
                            <Box color="gray.800">{test.itemName}</Box>
                        </Box>
                    ) : courseContent ? (
                        <Box alignItems="center">
                            <Box color="gray.800">{courseContent.itemName}</Box>
                        </Box>
                    ) : (
                        <Box alignItems="center">
                            <Box color="gray.800">{currentDoubt.user?.name}</Box>
                            <Box fontSize={13} color="gray.500">
                                <i>{moment(currentDoubt.createdAt).format("DD MMM YYYY, hh mm a")}</i>
                            </Box>
                        </Box>
                    )}

                </DrawerHeader>

                <DrawerBody style={{ maxHeight: "100vh" }}>
                    {test ? (
                        <Box>

                            <DiscussionComments
                                onBottomAtSmall={true}

                                commentsLabel="Comments"
                                showAttachment={false}
                                placeholder="Add a comment"
                                inputStyle="flushed"
                                itemId={test.itemId}
                                includeDoubt={2}
                            />
                        </Box>
                    ) : courseContent?.content ? (
                        <Box>

                            <DiscussionComments
                                onBottomAtSmall={true}

                                commentsLabel="Comments"
                                showAttachment={false}
                                placeholder="Add a comment"
                                inputStyle="flushed"
                                itemId={courseContent.itemId}
                                itemModel={courseContent.itemModel}
                                contentId={courseContent.content}
                                courseId={courseContent.course}
                                includeDoubt={2}
                            />
                        </Box>
                    ) : (
                        <DoubtComponent currentDoubt={currentDoubt} />
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
const DoubtsInfo = ({ currentTeacher, doubtInfo, searchDoubt, setDoubtInfo }) => {
    const dispatch = useDispatch()
    const teacherDoubts = useSelector((s) => s.doubts.teacherDoubts);
    const [files, setFiles] = useState([])
    useEffect(() => {
        if (teacherDoubts.length > 0) {
            if (doubtInfo?._id) {
                setDoubtInfo(teacherDoubts.find((e) => e._id === doubtInfo?._id))
            }
            else {
                setDoubtInfo(teacherDoubts[0])
            }
        }
    }, [teacherDoubts])
    useEffect(() => {
        dispatch(getAllTeachers())
    }, [])
    const [replyModal, setReplyModal] = useState({ visible: false, info: [] })
    const allTeachers = useSelector((state) => state.doubts.allTeachers);
    const [replyForm, setReplyForm] = useState({ message: "", files: [] });
    const handleReply = () => {
        setReplyModal({ visible: true, info: [] })
    }
    const handleUpdateDoubt = () => {
        if (replyForm.message.trim() == "") {
            alert("Please Enter Message");
            return;
        }
        const payload = {
            queryId: doubtInfo?.doubt?.[doubtInfo?.doubt.length - 1]._id,
            message: replyForm.message,
            staff: currentTeacher?.user._id,
        };
        if (files.length > 0) {
            payload.files = files;
        }
        dispatch(updateDoubtAction(payload))
        setTimeout(() => {
            dispatch(getTeacherDoubtsAction({ page: 1, staff: currentTeacher?.user._id, doubt_text_regex: searchDoubt }));
        }, 1000)
        setFiles([])
        setReplyForm({ message: "", files: [] })
        setReplyModal({ visible: false, info: [] })
    }

    const getFiles = (data) => {
        setReplyForm((prev) => { return { ...prev, files: data } });
    };

    return <>
        <Flex background="#E7E3E33B" borderRadius="10px" width="100%" justifyContent="space-between" alignItems="center">
            <Avatar />
            <Text fontWeight="bold">{doubtInfo?.userId?.name}</Text>
            {/* <Icon as={TbDotsVertical} /> */}
        </Flex>
        <VStack
            key={doubtInfo?._id}
            maxHeight="500px"
            overflowY="auto"
            width="100%"
            align="start"
            spacing={4}
            padding="4"
        >
            {doubtInfo?.doubt?.map((doubt) => (
                <Box
                    key={doubt._id}
                    width="100%"
                >
                    <Flex alignItems="center" mb={2}>

                        <Box>
                            <Box display="flex">
                                <Avatar mb={1} size="sm" />
                                <Text fontSize="md" fontWeight="bold">
                                    {doubtInfo.userId?.name}
                                </Text></Box>
                            <Text p={2} height="auto" width="auto" background="#797DFA" color="white" borderRadius="15px">{doubt.doubt_query}</Text>
                            {
                                doubt?.files?.length > 0 ?
                                    doubt.files.map((file) => (
                                        <div style={{ padding: "8px 15px", borderRadius: "0 10px 10px 10px", border: "1px solid #ABABAB4D", width: "max-content", maxWidth: "85%", margin: "10px auto 10px 15%" }}>
                                            {
                                                file?.mimeType?.includes("image") ?
                                                    <Image src={file?.url} style={{ maxWidth: "100%", margin: "0 0 10px", maxHeight: "200px" }} />
                                                    :
                                                    file?.mimeType?.includes("audio") ?
                                                        <audio controls>
                                                            <source src={file?.url} type={file?.mimeType} />
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                        :
                                                        file?.mimeType?.includes("video") ?
                                                            <video controls width="600">
                                                                <source src={file?.url} type={file?.mimeType} />
                                                                Your browser does not support the video element.
                                                            </video>
                                                            :
                                                            file?.mimeType?.includes("pdf") ?
                                                                <a href={file?.url} target="_blank">
                                                                    <HStack style={{ gap: "5px" }}>
                                                                        <Image src="/images/pdfFileIcon.png" width={30} />
                                                                        <Text
                                                                            color="#000"
                                                                            fontSize="16px"
                                                                            noOfLines={1}
                                                                            overflow="hidden"
                                                                            textOverflow="ellipsis"
                                                                            width={"calc(100% - 95px)"}
                                                                            style={{ margin: "0", padding: "0" }}
                                                                        >
                                                                            {file?.name}
                                                                        </Text>
                                                                        <Text
                                                                            color="#16B364"
                                                                            fontSize="13px"
                                                                            fontWeight="500"
                                                                            width={55}
                                                                            style={{ margin: "0", padding: "0" }}
                                                                        >
                                                                            {parseFloat((file?.size / 1048576).toFixed(2))} Mb
                                                                        </Text>
                                                                    </HStack>
                                                                </a>
                                                                : null
                                            }
                                        </div>
                                    ))
                                    : null
                            }

                        </Box>
                    </Flex>
                    {doubt.response.length > 0 ? (
                        <>
                            {doubt.response.map((resp) => (
                                <>
                                    <Flex display="flex" justifyContent="end" alignItems="center" width="100%">
                                        <Box>
                                            <Text fontWeight="bold">{ }</Text>
                                            <Text p={2} height="auto" width="auto" background="#001835" color="white" borderRadius="15px">{resp.message}</Text>
                                        </Box>
                                        <Avatar size="sm" ml={2} />
                                    </Flex>
                                        {/* {resp.files.length > 0 && (
                                            <>
                                                <Text fontSize="md" fontWeight="bold">
                                                    Attached Files:
                                                </Text>
                                                <Box display="flex" flexDirection="column" gap={2}>
                                                    {resp.files.map((file) => (
                                                        <Box key={file._id}>
                                                            <Link href={file.url} isExternal color="teal.500">
                                                                {file.name}
                                                            </Link>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </>
                                        )} */}
                                        {
                                            resp?.files?.length > 0 ?
                                                resp.files.map((file) => (
                                                    <div style={{ padding: "8px 15px", borderRadius: "0 10px 10px 10px", border: "1px solid #ABABAB4D", width: "max-content", maxWidth: "85%", margin: "10px auto 10px 250px" }}>
                                                        {
                                                            file?.mimeType?.includes("image") ?
                                                                <Image src={file?.url} style={{ maxWidth: "100%", margin: "0 0 10px", maxHeight: "200px" }} />
                                                                :
                                                                file?.mimeType?.includes("audio") ?
                                                                    <audio controls>
                                                                        <source src={file?.url} type={file?.mimeType} />
                                                                        Your browser does not support the audio element.
                                                                    </audio>
                                                                    :
                                                                    file?.mimeType?.includes("video") ?
                                                                        <video controls width="600">
                                                                            <source src={file?.url} type={file?.mimeType} />
                                                                            Your browser does not support the video element.
                                                                        </video>
                                                                        :
                                                                        file?.mimeType?.includes("pdf") ?
                                                                            <a href={file?.url} target="_blank">
                                                                                <HStack style={{ gap: "5px" }}>
                                                                                    <Image src="/images/pdfFileIcon.png" width={30} />
                                                                                    <Text
                                                                                        color="#000"
                                                                                        fontSize="16px"
                                                                                        noOfLines={1}
                                                                                        overflow="hidden"
                                                                                        textOverflow="ellipsis"
                                                                                        width={"calc(100% - 95px)"}
                                                                                        style={{ margin: "0", padding: "0" }}
                                                                                    >
                                                                                        {file?.name}
                                                                                    </Text>
                                                                                    <Text
                                                                                        color="#16B364"
                                                                                        fontSize="13px"
                                                                                        fontWeight="500"
                                                                                        width={55}
                                                                                        style={{ margin: "0", padding: "0" }}
                                                                                    >
                                                                                        {parseFloat((file?.size / 1048576).toFixed(2))} Mb
                                                                                    </Text>
                                                                                </HStack>
                                                                            </a>
                                                                            : null
                                                        }
                                                    </div>
                                                ))


                                                : null
                                        }
                                </>
                            ))}
                        </>
                    ) : (
                        <Text fontSize="md" color="gray.500">
                            No response yet.
                        </Text>
                    )}
                </Box>
            ))}
        </VStack>
        {console.log("doubtInfo.status", doubtInfo.status)}
        <Flex background="#E7E3E33B" borderRadius="10px" width="100%" justifyContent="center" alignItems="center">
            {(doubtInfo.status != "Responded"||doubtInfo.status!="Resolved") && <Button
                onClick={handleReply}
                _hover={{ boxShadow: "lg" }}
                size="lg"
                colorScheme="blue"
                ml={2}
                mb={2}
            >
                Reply
            </Button>}
        </Flex>
        <Modal size="lg" isOpen={replyModal.visible} onClose={() => setReplyModal({ visible: false, info: [] })}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Reply to Doubt</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Text mb={5}><b>Que:-</b>{doubtInfo?.doubt?.[doubtInfo?.doubt.length - 1].doubt_query}</Text>
                    <Input
                        placeholder="Type your reply..."
                        //    value={replyMessage}
                        onChange={(e) => setReplyForm((prev) => { return { ...prev, message: e.target.value } })}
                    />
                    <Box mt={5}>
                        <UploadAttachments addAttachment={true} files={files} setFiles={setFiles} />
                    </Box>

                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" onClick={() => setReplyModal({ visible: false, info: [] })}>
                        Cancel
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={handleUpdateDoubt}
                    >
                        Reply
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}

export const DoubtComponent = ({ currentDoubt }) => {
    const dispatch = useDispatch();

    const { closeDoubtStatus, publicDoubtStatus, user } = useSelector((state) => ({
        closeDoubtStatus: state.doubts.closeDoubtStatus,
        publicDoubtStatus: state.doubts.publicDoubtStatus,
        user: state.user.user,
    }));

    const checkRole = useMemo(() => {
        return user?.staff?.staffRole === "ADMIN" || user?.staff?.staffRole === "HEAD_TEACHER" || user?.staff?.staffRole === "TEACHER";
    }, [user?.staff?.staffRole]);

    const handleClose = () => {
        let data = {
            status: "Closed",
            doubtId: currentDoubt._id,
            closedDate: moment().format(),
        };
        dispatch(closeDoubtAction(data));
    };

    const handlePublic = () => {
        let data = {
            doubtId: currentDoubt._id,
            public: currentDoubt.public ? false : true,
        };
        dispatch(publicDoubtAction(data));
    };

    return (
        <>
            <HStack>
                <Button disabled={currentDoubt.status === "Closed"} size="sm" isLoading={closeDoubtStatus === STATUS.FETCHING} onClick={handleClose} variant="outline" color={"brand.red"}>
                    {currentDoubt.status === "Closed" ? "Closed" : "Close Doubt"}
                </Button>
                <Button
                    size="sm"
                    isLoading={publicDoubtStatus === STATUS.FETCHING}
                    variant="outline"
                    onClick={handlePublic}
                    color={currentDoubt.public ? "brand.red" : "brand.green"}
                    disabled={currentDoubt.status === "Open"}
                >
                    {currentDoubt.public ? "Make it Private" : "Make it Public"}
                </Button>
            </HStack>
            <br />
            <Box
                fontSize="md"
                color={"brand.secondary"}
                fontFamily='"Lato", sans-serif'
                as="pre"
                py={3}
                sx={{
                    "white-space": "-moz-pre-wrap",
                    "white-space": "-o-pre-wrap",
                    "word-wrap": "break-word",
                    "white-space": "pre-wrap",
                }}
            >
                {currentDoubt.doubt}
            </Box>
            <br />
            <br />
            <Box>
                <DiscussionComments
                    onBottomAtSmall={true}
                    editCommentUserId={checkRole && user._id}
                    commentsLabel="Comments"
                    showAttachment={true}
                    placeholder="Add a comment"
                    inputStyle="flushed"
                    itemId={currentDoubt._id}
                />
            </Box>
        </>
    );
};


const UploadAttachments = ({ addAttachment, files = { files }, setFiles = { setFiles } }) => {
    const dispatch = useDispatch();
    const { totalSize, countFiles } = useMemo(() => {
        const s =
            files?.length &&
            _.chain(files)
                .map((d) => d.size / 1000)
                .sum()
                .round(2)
                .value();
        const c = files?.length;
        return { totalSize: s, countFiles: c };
    }, [files]);



    const _uploadFile = async (e) => {
        if (countFiles > 10) {
            return;
        }
        let item = { file: e.target.files?.[0] };

        let form_data = new FormData();

        for (let key in item) {
            form_data.append(key, item[key]);
        }

        try {
            const response = await fetch(BaseURL + "app/image", {
                method: 'POST',
                body: form_data,
            });

            const data = await response.json();
            const formatted = {
                "mimeType": data.type,
                "name": data.fileName,
                "size": data.size,
                "url": data.url
            }
            setFiles((prev) => { return [...prev, formatted] })
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };


    const removeFile = (indx) => {
        setFiles((data) => _.filter(data, (d, i) => i !== indx));
    };

    return (
        <Box>
            <Text fontWeight="bold">{addAttachment ? "Add Attachments" : "Upload Your Answers"}</Text>
            <Container>
                Please note that you can select a max of 10 files and total size not
                exceeding 10MB
            </Container>
            <br />
            <Input
                type="file"
                multiple
                onChange={_uploadFile}
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*,audio/*,video/*"
            />
            <br />
            <br />
            {files && files.length ? (
                <Box>
                    <Box fontSize="14px" p={2} background="#F4F6F6" borderRadius={7}>
                        <HStack spacing={6}>
                            <Box>
                                Size: <b>{totalSize} KB</b>
                            </Box>
                            <Box>
                                Count Files: <b>{countFiles}</b>
                            </Box>
                        </HStack>
                        <List align="start" mt={5}>
                            {files.map((f, key) => (
                                <ListItem
                                    px={2}
                                    fontSize="13px"
                                    _hover={{ background: "white" }}
                                    border="1px solid #E5E8E8"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                    key={key}
                                >
                                    <Text>{f?.name}</Text>
                                    <Tooltip label="remove" placement="left">
                                        <IconButton
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => removeFile(key)}
                                            icon={<DeleteIcon />}
                                        />
                                    </Tooltip>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    {totalSize > 10500 || countFiles > 10 ? (
                        <Text color="red.500">
                            Count or file sizes exceeding the threshold limit, Please delete
                            some or use compression.
                        </Text>
                    ) : (
                        <Text color="yellow.500">
                            Please note that after submitting your assignment you will not be
                            able to make changes to it. To confirm please click here and
                            submit.
                        </Text>
                    )}
                </Box>
            ) : null}
        </Box>
    );
};

